import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  { path: '', redirect: '/overview' },
  { path: '/locations', component: () => import('../views/LocationsList.vue') },
  { path: '/locations/refresh/:any', component: () => import('../views/LocationsList.vue') },
  { path: '/locations/:id', component: () => import('../views/LocationsEdit.vue') },
  { path: '/organisations', component: () => import('../views/OrganisationsList.vue') },
  { path: '/organisations/refresh/:any', component: () => import('../views/OrganisationsList.vue') },
  { path: '/organisations/:id', component: () => import('../views/OrganisationsEdit.vue') },
  { path: '/users', component: () => import('../views/UsersList.vue') },
  { path: '/users/refresh/:any', component: () => import('../views/UsersList.vue') },
  { path: '/user/:id', component: () => import('../views/UsersEdit.vue') },

  { path: '/messages', component: () => import('../views/MessagesList.vue') },
  { path: '/messages/refresh/:any', component: () => import('../views/MessagesList.vue') },

  { path: '/settings', component: () => import('../views/AdminSettings.vue') },
  { path: '/overview', component: () => import('../views/AdminHome.vue') },
  { path: '/login', component: () => import('../views/AdminLogin.vue') }, 
  { path: '/locations/events/edit/:id', component: () => import('../views/LocationEventEdit.vue') },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to) => {
  if (!localStorage.getItem('user') && to.path !== '/login') {
    // redirect the user to the login page
    return '/login';
  }
})

export default router
