<template>
  <ion-app>

    <!-- Login else show app -->
    <ion-router-outlet v-if="$route.path == '/login'"></ion-router-outlet>

    <ion-split-pane content-id="main-content" v-if="$route.path != '/login' && user.email">
      <ion-menu content-id="main-content" type="overlay">

        <ion-header color="tertiary">
          <ion-toolbar color="tertiary">
            <div class="ion-padding">
              <ion-searchbar placeholder="Søg brugere, lokationer, organisationer mv."></ion-searchbar>
            </div>
          </ion-toolbar>
        </ion-header>
        <ion-content>

          <ion-list>
            
            <ion-list-header>Navigation</ion-list-header>
            <ion-menu-toggle auto-hide="false">
              <ion-item button router-link="/overview" lines="none" detail="true" :class="{ selected: $route.path == '/overview'}">
                <ion-icon :icon="listOutline" slot="start"></ion-icon>
                <ion-label>Oversigt</ion-label>
              </ion-item>
              <ion-item button @click="$router.push('/locations')" lines="none" detail="true"
                :class="{ selected: $route.path == '/locations'}">
                <ion-icon :icon="locationOutline" slot="start"></ion-icon>
                <ion-label>Lokationer</ion-label>
              </ion-item>
              <ion-item button @click="$router.push('/organisations')" lines="none" detail="true" :class="{ selected: $route.path == '/organisations'}">
                <ion-icon :icon="earthOutline" slot="start"></ion-icon>
                <ion-label>Organisationer</ion-label>
              </ion-item>
              <ion-item button @click="$router.push('/users')" lines="none" detail="true" :class="{ selected: $route.path == '/users'}">
                <ion-icon :icon="peopleOutline" slot="start"></ion-icon>
                <ion-label>Brugere</ion-label>
              </ion-item>
              <ion-item button @click="$router.push('/messages')" lines="none"
                detail="true" :class="{ selected: $route.path == '/messages'}">
                <ion-icon :icon="mailOutline" slot="start"></ion-icon>
                <ion-label>Beskeder</ion-label>
              </ion-item>
            </ion-menu-toggle>

          </ion-list>

        </ion-content>
      </ion-menu>
   
      <div class="ion-page" id="main-content">
        <ion-header>
          <ion-toolbar color="tertiary" class="admin-toolbar">
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-title slot="start">SMOLTVAGT</ion-title>
            <ion-title class="userTitle" v-if="user" slot="end">
              {{user.name}} 
              <br />
              <small>{{ (user.is_admin == 1 ? 'Administrator' : 'Bruger') }}</small>
            </ion-title>
            <!-- <img class="avatar" v-if="user" slot="end" src="/assets/avatar.svg" alt="{{ user.name }}" /> -->
            <ion-button button alt="Dine indstillinger" title="Dine indstillinger" @click="$router.push('/settings')" slot="end" color="light" fill="clear"><ion-icon :icon="settingsOutline"></ion-icon></ion-button>
            <ion-button button alt="Log ud" title="Log ud" @click="logOut()" slot="end" color="light" fill="clear"><ion-icon :icon="logOutOutline"></ion-icon></ion-button>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-router-outlet id="main-content"></ion-router-outlet>
        </ion-content>
      </div>
  
    </ion-split-pane>
  </ion-app>
</template>

<script>
import {
  IonButtons,
  IonIcon,
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonMenuButton,
  IonButton,
  IonRouterOutlet,
  IonSplitPane,
  IonSearchbar,
  IonHeader,
  IonTitle,
  IonToolbar,
  
} from '@ionic/vue';
import { mailOutline, locationOutline, earthOutline, calendarNumberOutline, listOutline, peopleOutline, calendarOutline, settingsOutline, logOutOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    IonButtons,
    IonApp,
    IonIcon,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonMenuButton,
    IonButton,
    IonRouterOutlet,
    IonSplitPane,
    IonSearchbar,
    IonHeader,
    IonTitle,
    IonToolbar,
    
  },
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"))
    setTimeout(() => {
      this.user = JSON.parse(localStorage.getItem("user"))
    }, 60000)
  },
  methods: {
    setUser() {
      this.user = JSON.parse(localStorage.getItem("user"))
    },
    logOut() {
      localStorage.removeItem("user")
      this.$router.push("/login")
    }
  },
  setup() {
    return { mailOutline, locationOutline, earthOutline, calendarNumberOutline, listOutline, peopleOutline, calendarOutline, settingsOutline, logOutOutline }
  }
});

</script>

<style scoped>
.admin-toolbar {
  padding: 12px 0px;
}
.userTitle {
  line-height: 18px;
}
.avatar {
  width: 80px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 50px;
}
ion-item.selected {
  --color: var(--ion-color-tertiary);
}
</style>
